import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import { useSpring, animated } from 'react-spring'

import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'

import Web3Connecter from "../web3connector"

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles(theme => ({
	baseBar: {
		backgroundColor: theme.body.basePageColorTrans,
		zIndex: 100,
		height: 75,
		top: 0
	},
	notifyHeadBg: {
		// backgroundColor: `${theme.body.backgroundAccent} !important`,
		color: `${theme.text.primary} !important`,
		padding: `0px !important`,
		margin: `0px !important`,
		outline: 'none !important'
	},
	notifyInputBg: {
		backgroundColor: theme.body.subscribeBg,
		outline: 'none !important',
		color: `${theme.text.primary} !important`,
	},
	notifyButton: {
		backgroundColor: `transparent !important`,
		borderBottom: `3px solid ${theme.body.shelfBackgroundColorPink} !important`,
		color: `${theme.text.primary} !important`,
		padding: `0px !important`,
		margin: `0px !important`,
		outline: 'none !important',
		"&:hover": {
			borderBottom: `3px solid ${theme.body.shelfBackgroundColorBlue} !important`,
			cursor: "pointer"
		}
	},
	buttons: {
		color: theme.text.primary,

	},
	button: {
		fontWeight: `900 !important`
	},
	buttonFull: {
		fontWeight: `900 !important`,
		borderBottom: "3px solid transparent",
		borderTop: "3px solid transparent",
		"&:hover": {
			borderBottom: `3px solid ${theme.body.shelfBackgroundColorPink}`,
		}
	},
	buttonFullActive: {
		fontWeight: `900 !important`,
		borderBottom: `3px solid ${theme.body.shelfBackgroundColorBlue}`,
		borderTop: "3px solid transparent"
	},
	drawerButton: {
    color: theme.text.primary,
    zIndex: 100000
  },
	drawerPaper: {
		width: "75%",
		overflowX: "hidden",
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
		// backgroundColor: theme.body.accent2Primaryl0,
		background: `linear-gradient(${theme.body.accent2Primaryl0}, ${theme.body.accent2Primaryl0}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		height: "100%"
	},
	mobileConnector: {
		height: 75
	}
}),
{ index: 2 })

export default function Navbar(props) {
	const classes = useStyles()
  const history = useHistory()
	const [sliderDrawer, setSliderDrawer] = useState(false);

	const goClaim = () => {
    	window.location.href = "https://claim.rliq.live";
    }

	const goDocs = () => {
    	window.location.href = "https://rareliquid.gitbook.io/rareliquid";
    }

	const goWallet = () => {
    history.push("/collection")
		props.updatePage('collection')
		setSliderDrawer(false)
  }

  const goMint = () => {
    history.push("/mint")
		props.updatePage('mint')
		setSliderDrawer(false)
  }

	const goHome = () => {
    history.push("/")
		props.updatePage('')
		setSliderDrawer(false)
  }

	const goAbout = () => {
    history.push("/about")
		props.updatePage('about')
		setSliderDrawer(false)
  }

	const goRoadmap = () => {
		history.push("/roadmap")
		props.updatePage('roadmap')
		setSliderDrawer(false)
	}

	const goFAQs = () => {
		history.push("/faqs")
		props.updatePage('faqs')
		setSliderDrawer(false)
	}

	const goTeam = () => {
		history.push("/team")
		props.updatePage('team')
		setSliderDrawer(false)
	}

	const toggleSliderDrawer = (open) => (event) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    // 	return;
    // }
    setSliderDrawer(open)
  };

	return (
		<>
			<div className={`${classes.baseBar} w-full h-auto absolute`}>
				<div className={`invisible w-0 md:visible md:w-full flex justify-between top-0 left-0 z-index h-full absolute top-0 left-0 `}>
					<div className={`flex-grow flex ${classes.buttons}`}>
					<button onClick={() =>{goHome()}} className={`${props.active === '' ? classes.buttonFullActive : classes.buttonFull} p-6 flex justify-center items-center`}>Home</button>
						{
							process.env.REACT_APP_COUNTDOWN_TO < Date.now() ?
								<>
									<a id={`_go_mint`} href={`https://mint.rareliquid.fi/mint`}  className={`${props.active === 'mint' ? classes.buttonFullActive : classes.buttonFull} p-6 flex justify-center items-center`}>Mint</a>
									<button onClick={() =>{goWallet()}}  className={`${props.active === 'collection' ? classes.buttonFullActive : classes.buttonFull} p-6 flex justify-center items-center`}>Collection</button>
								</>
							:
								<>
								</>
						}
						<button onClick={() =>{goClaim()}} className={` ${props.active === 'claim' ? classes.buttonFullActive : classes.buttonFull} p-6 flex justify-center items-center`}>Claim</button>
						<button onClick={() =>{goDocs()}} className={`${props.active === 'docs' ? classes.buttonFullActive : classes.buttonFull} p-6 flex justify-center items-center`}>Documentation</button>
						<button onClick={() =>{goFAQs()}} className={`${props.active === 'faqs' ? classes.buttonFullActive : classes.buttonFull} p-6 flex justify-center items-center`}>FAQs</button>
						<button onClick={() =>{goTeam()}} className={`${props.active === 'team' ? classes.buttonFullActive : classes.buttonFull} p-6 flex justify-center items-center`}>Team</button>
					</div>
					<div className={`w-auto flex justify-center items-center`}>
						<Web3Connecter key={`web3connector`} web3State={props.web3State} disconnectWallet={() => {props.disconnectWallet()}} connectWallet={() => {props.connectWallet()}} address={props.address}/>
					</div>
	      </div>

				<div className={`visible w-full md:invisible md:w-0 flex justify-between items-center top-0 left-0 z-index h-full p-6 absolute top-0 left-0 `}>
					<button className={` text-lg ${classes.drawerButton}`} onClick={toggleSliderDrawer( true)}>
						<MenuIcon fontSize={`large`} />
					</button>
					<SwipeableDrawer anchor={`left`} open={sliderDrawer}  onClose={toggleSliderDrawer(false)} onOpen={toggleSliderDrawer(true)} classes={{
            paper: classes.drawerPaper,
          }}>
						<div className={`w-full flex justify-center items-center ${classes.mobileConnector}`}>
							<Web3Connecter key={`web3connector`} web3State={props.web3State} disconnectWallet={() => {props.disconnectWallet()}} connectWallet={() => {props.connectWallet()}} address={props.address}/>
						</div>
						<div className={`flex-grow flex-col justify-start items-start flex ${classes.buttons} px-12`}>
							<button onClick={() =>{goHome()}} className={`${classes.button} py-4  w-full text-lg flex justify-start items-center`}>Home</button>
							{
								process.env.REACT_APP_COUNTDOWN_TO <= Date.now() ?
									<>
										<a href={`https://mint.rareliquid.fi/mint`}  className={`${classes.button}  w-full py-4  text-lg flex justify-start items-center`}>Mint</a>
										<button onClick={() =>{goWallet()}}  className={`${classes.button}  w-full py-4 text-lg  flex justify-start items-center`}>Collection</button>
									</>
								:
									<>
									</>
							}
							<button onClick={() =>{goClaim()}} className={`${classes.button}  w-full py-4 text-lg flex justify-start items-center`}>Claim</button>
							<button onClick={() =>{goDocs()}} className={`${classes.button}  w-full py-4 text-lg  flex justify-start items-center`}>Documentation</button>
							<button onClick={() =>{goFAQs()}} className={`${classes.button}  w-full py-4  text-lg flex justify-start items-center`}>FAQs</button>
							<button onClick={() =>{goTeam()}} className={`${classes.button}  w-full py-4 text-lg  flex justify-start items-center`}>Team</button>
							<div id="mc_embed_signup" className={`md:invisible visible bottom-0 w-full flex-col flex flex-grow justify-end`}>
								<form action="https://rareliquid.us1.list-manage.com/subscribe/post?u=e9a25c5c90041d4bbe8f5ff18&amp;id=f071a55aa5" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" className={classes.noPadding} target="_blank" novalidate>
									<div id="mc_embed_signup_scroll" className={`py-4 flex`}>
										<div className={`flex flex-col w-full h-auto justify-between mb-2`}>
											<label className={`flex justify-center my-2  items-center text-base font-bold w-full ${classes.notifyHeadBg}`} for="mce-EMAIL">Subscribe To Drop</label>
											<input className={`w-full h-12 my-2 font-bold ${classes.notifyInputBg} text-base`} type="email"  name="EMAIL" id="mce-EMAIL" />
											<div className={`h-12 w-full flex my-2 justify-center items-center`}>
												<input className={`h-12 w-36 flex justify-center items-center text-base font-bold w-24 ${classes.notifyButton}`} type="submit" value="Notify Me" name="subscribe" id="mc-embedded-subscribe" />
											</div>
										</div>
										<div id="mce-responses" class="clear">
											<div class="response" id="mce-error-response" style={{display:"none"}}></div>
											<div class="response" id="mce-success-response" style={{display:"none"}}></div>
										</div>
										<div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_e9a25c5c90041d4bbe8f5ff18_f071a55aa5" tabindex="-1" value=""/></div>
									</div>
								</form>
							</div>
						</div>


					</SwipeableDrawer>

	      </div>
			</div>
    </>
	)
}
// <AnimatedIcon key={`animated`} theme={props.theme} updateTheme={(t) => props.updateTheme(t)}/>

const AnimatedIcon = (props) => {
  const [isDarkMode, setDarkMode] = React.useState(props.theme !== "DARK" ? false : true);

  const toggleDarkMode = () => {
    let newTheme = !isDarkMode
    setDarkMode(newTheme);
    props.updateTheme(!newTheme ? "LIGHT" : "DARK")
  };

  const properties = {
    dark: {
      r: 9,
      transform: "rotate(40deg)",
      cx: 12,
      cy: 4,
      opacity: 0,
    },
    light: {
      r: 5,
      transform: "rotate(90deg)",
      cx: 30,
      cy: 0,
      opacity: 1,
    },
    springConfig: { mass: 4, tension: 250, friction: 35 }
  };

  const { r, transform, cx, cy, opacity } = properties[
    isDarkMode ? "dark" : "light"
  ];

  const svgContainerProps = useSpring({
    transform,
    config: properties.springConfig
  });
  const centerCircleProps = useSpring({ r, config: properties.springConfig });
  const maskedCircleProps = useSpring({
    cx,
    cy,
    config: properties.springConfig
  });
  const linesProps = useSpring({ opacity, config: properties.springConfig });

  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      onClick={toggleDarkMode}
      style={{
        cursor: "pointer",
        ...svgContainerProps
      }}
    >
      <mask id="myMask2">
        <rect x="0" y="0" width="100%" height="100%" fill={!isDarkMode ? "transparent" : "white"} />
        <animated.circle style={maskedCircleProps} r="9" fill={!isDarkMode ? "black" : "red"} />
      </mask>

      {
        !isDarkMode ?
          <>
            <animated.circle
              cx="12"
              cy="12"
              style={centerCircleProps}
              fill="#74c3ce"
              mask="url(#myMask2)"
            />
          </>
        :
          <>
            <animated.circle
              cx="12"
              cy="12"
              style={centerCircleProps}
              fill="white"
              mask="url(#myMask2)"

            />
          </>
      }

      <animated.g stroke={!isDarkMode ? "#74c3ce" : "white"} style={linesProps}>
        <line x1="12" y1="1" x2="12" y2="3" />
        <line x1="12" y1="21" x2="12" y2="23" />
        <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
        <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
        <line x1="1" y1="12" x2="3" y2="12" />
        <line x1="21" y1="12" x2="23" y2="12" />
        <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
        <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
      </animated.g>
    </animated.svg>
  );
};

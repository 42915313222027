import React, {useEffect, useState, useRef} from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { Link, animateScroll as scroll } from "react-scroll";

import {useHistory} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'
import logo from '../../assets/bottle_main.png'
import Wave from 'react-wavify'
import Minter from "../../components/minter.js"

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import ReactDOMServer from "react-dom/server";

import MobileBottleMinter from '../../assets/main_interior_mobile.gif'
import Button from '../../assets/button.gif'
import {getMetadata} from '../../services/api'
import FastAverageColor from 'fast-average-color';
import SocialsWithTermsAlt from "../../components/socials"
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
	cardBackground: {
		backgroundColor: theme.body.basePageColorTrans,
		color: theme.text.primary
	},
	modalBackground: {
		backgroundColor: theme.body.basePageColor,

	},
	modalPopup: {
		backgroundColor: theme.body.modalPopupColor,
		color: theme.text.primary
	},
	pageHeight: {
		marginTop: '75px',
		height: 'calc(100vh - 75px)',
		overflow: "auto"
	},
	cardTitle: {
		fontSize: 40,
		fontWeight: 900
	},
	cardContent: {
		fontSize: 20,
		fontWeight: 900
	},
	shelfBackground: {
		backgroundColor: theme.body.shelfBackgroundColor,
		boxShadow: `0px 0px 5px grey, inset -2px -20px 20px 0px #ffffff0f`,
		height: 150
	},
	shelfBackgroundBlue: {
		boxShadow: `0px 0px 5px ${theme.body.shelfBackgroundColorBlue}, inset  -2px -20px 20px 0px  ${theme.body.shelfBackgroundColorBlue}0f`
	},
	shelfBackgroundPink: {
		boxShadow: `0px 0px 5px ${theme.body.shelfBackgroundColorPink}, inset  -2px -20px 20px 0px  ${theme.body.shelfBackgroundColorPink}0f`
	},
	modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
	paginationHover: {
		"&:hover": {
			borderBottom: `3px solid ${theme.body.shelfBackgroundColorBlue}`
		}
	},
	pagination: {
		borderBottom: `3px solid transparent`
	}
}),
{ index: 2 })

export default function Wallet(props) {
	const classes = useStyles()
	let history = useHistory()
	const myRef = React.createRef()

	const [open, setOpen] = useState({
		state: false,
		token: {
			image: null,
			name: null,
			color: null
		}
	});
	const handleClose = () => {
		setOpen({
			state: false,
			token: {
				image: null,
				name: null,
				color: null
			}
		});
	};

	const handleOpen = (token, name) => {
		let fac = new FastAverageColor();
		fac.getColorAsync(token)
      .then(color => {
					setOpen({
						state: true,
						token: {
							image: token,
							name: name,
							color: `${color.hex}6f`
						}
					});
      })
      .catch(e => {
          console.log(e);
      });

	};

	const [shelfHexes, setShelfHexes] = useState([]);
	const [count, setCount] = useState(20);
	//common
	const [commonTokens, setCommonTokens] = useState({
		"ids": [],
		"hashes": [],
		"metadata": []
	});
	const [firstCommonToken, setFirstCommonToken] = useState(0);
	const [previousCommonIndex, setPreviousCommonIndex] = useState([0]);
	const [pageCommonIndex, setPageCommonIndex] = useState(count)
	const [balance, setBalance] = useState(0)

	const [localState, setLocalState] = useState({
		history: {}
	})

	useEffect(() => {
		setLocalState({
			history: props.history["web3Object"]
		})

	},[props.history["web3Object"]])

	const getTokenMetadata = async (id) => {
		return new Promise(resolve => {
			getMetadata(id).then((res) => {
				resolve(res)
			})
	  });
	}

	const getTokensMetadata = async (ids) => {
		let metadata = []
		for (let id of ids) {
			if (id !== "0") {
				let data = await getTokenMetadata(id)
				metadata.push(data)
			} else {
				metadata.push(null)
			}
		}
		return metadata
	}

	const pushTokenToDom = (i, token, _id) => {
		if (token === null) {
			if (document.getElementById(i) !== null) {
				document.getElementById(i).innerHTML = ""
			}
		} else {
			document.getElementById(i).innerHTML =
				`<button id=${_id} class='flex justify-center w-24 md:w-36 flex-col items-center'>
						<img width="100%" src=${token.image} />
				</button>`
		}
	}

	useEffect(() => {
		if (commonTokens.metadata.length && commonTokens.metadata.length) {
			let tokens = []
			for(let token in commonTokens.metadata) {
				let id = uuidv4()
				pushTokenToDom(token, commonTokens.metadata[token], id)
				if (document.getElementById(id) !== null) {
					document.getElementById(id).addEventListener('click', () => {
						eventListener(commonTokens.metadata[token].image, commonTokens.metadata[token].name)
					}, false)
				}
			}
		}
	}, [commonTokens])

	useEffect(() => {
		if (localState.history && localState.history["connected"] && !commonTokens.length && shelfHexes.length === 0) {
			getCommonTokens(parseInt(previousCommonIndex[previousCommonIndex.length - 1]))
			let hexes = Array.from(localState.history.web3.utils.stringToHex(localState.history.currentAccount).substring(2)).filter(x => {
				if (x !== "0" && parseInt(x) > 1) {
					return x
				}
			}).slice(0, 6)
			setShelfHexes(hexes)
		}
	},[localState])

	useEffect(() => {
		if (localState.history && localState.history["connected"]) {
			getCommonTokens(parseInt(previousCommonIndex[previousCommonIndex.length - 1]))
		}

	},[previousCommonIndex])

	const getCommonTokens = (i) => {
		localState.history.nftContract.methods.getOwnerTokenList(localState.history.currentAccount, count, i).call().then((res) => {
			organizeData(res)
		});
	}

	const organizeData = async (ids) => {
		let getFilteredIds = ids.ownerTokenIds
		let getFilteredHashes = ids.hashToTokenIds
		let metadata = await getTokensMetadata(getFilteredIds)

		setCommonTokens({
			ids: getFilteredIds,
			hashes: getFilteredHashes,
			metadata: metadata
		});
		if (firstCommonToken === null) {
			setFirstCommonToken(ids[0])
		}
	}

	const getPrev = () => {
		if (previousCommonIndex.length === 1) {
			return
		}

		setCommonTokens({
			"ids": [],
			"hashes": [],
			"metadata": []
		})
		let lastIndex;
		let newList;

		lastIndex = previousCommonIndex.splice(previousCommonIndex.length - 1)
		newList = previousCommonIndex.filter((x) => {return x!==lastIndex})
		setPageCommonIndex(pageCommonIndex - count)
		setPreviousCommonIndex(newList)
	}

	const getNext = () => {
		if (pageCommonIndex > commonTokens.ids.length) {
			return
		}
		setCommonTokens({
			"ids": [],
			"hashes": [],
			"metadata": []
		})
		setPageCommonIndex(pageCommonIndex + count)
		setPreviousCommonIndex([...previousCommonIndex, pageCommonIndex])
	}

	const eventListener = (image, name) => {
		handleOpen(image, name)
	}

	const buildShelves = () => {
		if (localState.history.web3) {
			let shelves = []
			let count = 0

			for (let hex in shelfHexes) {
				switch(true) {
					case (parseInt(shelfHexes[hex]) * parseInt(shelfHexes[hex]) % 7 === 0):
						shelves.push(
							<div className={`flex flex-col md:flex-row w-full md:my-2`}>
								<div className={`flex md:justify-around items-center ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex) % 3 === 0 ? classes.shelfBackgroundPink : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex)+3 % 7 === 0 ? classes.shelfBackgroundBlue : ``} flex md:mr-2 flex-grow`}>
									<div id={count} className={`flex flex-grow items-center justify-center`} ></div>
									<div id={count+1} className={`flex flex-grow items-center justify-center`} ></div>
								</div>
								<div className={`flex md:justify-around items-center ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex) % 7 === 0 ? classes.shelfBackgroundBlue : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex)+4 % 5 === 0 ? classes.shelfBackgroundPink : ``}  flex md:mx-2 flex-grow`}>
									<div id={count+2} className={`flex flex-grow items-center justify-center`} ></div>
									<div id={count+3} className={`flex flex-grow items-center justify-center`} ></div>
								</div>
								<div  className={`flex md:justify-around items-center ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex) % 2 === 0 ? classes.shelfBackgroundBlue : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex)+5 % 4 === 0 ? classes.shelfBackgroundPink : ``} flex md:ml-2 flex-grow`}>
									<div id={count+4} className={`flex flex-grow items-center justify-center`} ></div>
									<div id={count+5} className={`flex flex-grow items-center justify-center`} ></div>
								</div>
							</div>
						)
						count = count + 6
						break
					case (parseInt(shelfHexes[hex]) * parseInt(shelfHexes[hex])  % 5 === 0):
						shelves.push(
							<div className={`flex flex-col md:flex-row w-full md:my-2`}>
								<div className={`flex md:justify-around items-center ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex) % 2 === 0 ? classes.shelfBackgroundPink : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex)+1 % 5 === 0 ? classes.shelfBackgroundBlue : ``} flex md:mr-2 flex-grow`}>
									<div id={count} className={`flex flex-grow items-center justify-center`} ></div>
									<div id={count+1} className={`flex flex-grow items-center justify-center`} ></div>
								</div>
								<div className={`flex md:justify-around items-center ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex) % 3 === 0 ? classes.shelfBackgroundBlue : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex)+2 % 2 === 0 ? classes.shelfBackgroundPink : ``}  flex md:mx-2 flex-grow`}>
									<div id={count+2} className={`flex flex-grow items-center justify-center`} ></div>
									<div id={count+3} className={`flex flex-grow items-center justify-center`} ></div>
								</div>
								<div  className={`flex md:justify-around items-center ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex) % 7 === 0 ? classes.shelfBackgroundBlue : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex)+3 % 2 === 0 ? classes.shelfBackgroundPink : ``} flex md:ml-2 flex-grow`}>
									<div id={count+4} className={`flex flex-grow items-center justify-center`} ></div>
									<div id={count+5} className={`flex flex-grow items-center justify-center`} ></div>
								</div>
							</div>
						)
						count = count + 6
						break
					case (parseInt(shelfHexes[hex]) * parseInt(shelfHexes[hex])  % 4 === 0):
						shelves.push(
							<div className={`flex flex-col md:flex-row w-full md:my-2`}>
								<div  className={`flex md:justify-around items-center ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex) % 4 === 0 ? classes.shelfBackgroundPink : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex)+1 % 7 === 0 ? classes.shelfBackgroundBlue : ``} flex md:mr-2 flex-grow`}>
									<div id={count} className={`flex flex-grow items-center justify-center`} ></div>
									<div id={count+1} className={`flex flex-grow items-center justify-center`} ></div>
									</div>
									<div  className={`flex md:justify-around items-center ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex)+1 % 7 === 0 ? classes.shelfBackgroundBlue : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex)+2 % 3 === 0 ? classes.shelfBackgroundPink : ``}  flex md:ml-2 flex-grow`}>
									<div id={count+2} className={`flex flex-grow items-center justify-center`} ></div>
									<div id={count+3} className={`flex flex-grow items-center justify-center`} ></div>
								</div>
							</div>
						)
						count = count + 4
						break
					case (parseInt(shelfHexes[hex]) * parseInt(shelfHexes[hex])  % 3 === 0):
						shelves.push(
							<div className={`flex flex-col md:flex-row md:justify-around md:items-center w-full md:my-2`}>
								<div  className={`${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex) % 2 === 0 ? classes.shelfBackgroundBlue : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex)+1 % 2 === 0 ? classes.shelfBackgroundBlue : ``}  flex md:mr-2 flex-grow`}>
									<div id={count} className={`flex flex-grow items-center justify-center`}></div>
									<div id={count+1} className={`flex flex-grow items-center justify-center`} ></div>
									</div>
									<div  className={`flex md:justify-around md:items-center ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(hex)+1 % 3 === 0 ? classes.shelfBackgroundPink : ``} ${parseInt(shelfHexes[hex]) * parseInt(hex) % 4 === 0 ? classes.shelfBackgroundPink : ``}  flex md:ml-2 flex-grow`}>
									<div id={count+2} className={`flex flex-grow items-center justify-center`} ></div>
									<div id={count+3} className={`flex flex-grow items-center justify-center`} ></div>
								</div>
							</div>
						)
						count = count + 4
						break
					default:
						shelves.push(
							<div className={`flex flex-col md:flex-row justify-around items-center  md:my-2 ${classes.shelfBackground} ${parseInt(shelfHexes[hex]) * parseInt(shelfHexes[hex]) % 7 === 0 ? classes.shelfBackgroundBlue : ``} ${parseInt(shelfHexes[hex]) * parseInt(shelfHexes[hex]) % 5 === 0 ? classes.shelfBackgroundPink : ``} flex flex-grow w-full my-1`}>
								<div id={count} className={`flex flex-grow items-center justify-center`} ></div>
								<div id={count+1} className={`flex flex-grow items-center justify-center`} ></div>
								<div id={count+2} className={`flex flex-grow items-center justify-center`} ></div>
								<div id={count+3} className={`flex flex-grow items-center justify-center`} ></div>
							</div>
						)
						count = count + 4
						break
				}
			}

			return(
				<div className={`flex flex-wrap w-full h-full`}>
					{shelves}
				</div>
			)
		}
	}

	const canGoNext = () => {
		if (pageCommonIndex > commonTokens.ids.length) {
			return false
		} else {
			return true
		}
	}

	const canGoPrev = () => {
		if (previousCommonIndex.length === 1) {
			return false
		} else {
			return true
		}
	}

	return (
		<>
			<div className={`h-screen `} ref={myRef} >
				<div id={`info_section`}
					className={`h-auto w-full flex flex-col justify-start items-center relative `}
					style={{
						backgroundImage: `url(https://storage.googleapis.com/rareliquid-web/main_interior.gif)`,
						backgroundPosition: "bottom",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat"
						}}>
					<AnimatePresence>
					<div className={`${classes.pageHeight} w-full  md:py-12 `}>
						<div className={` w-full md:max-w-screen-xl md:mx-auto md:py-12 h-auto min-h-full flex overflow-x-hidden`}>
							<div className={`${classes.cardBackground} h-auto p-4 md:p-12 min-h-full w-full flex-grow`}>
								<div className={`flex flex-col md:flex-row justify-between`}>
									<div className={`${classes.cardTitle} md:pb-12 `}>
										My Collection
									</div>
									<div className={`flex justify-between items-center`}>
										<button onClick={() => {getPrev()}} className={`md:w-24 flex justify-center py-2  items-center text-xl ${classes.pagination} ${canGoPrev() ? `${classes.paginationHover} font-bold` : `` }`}>{`Prev`}</button>
										<div className={`md:w-24 text-2xl flex justify-center py-2 font-bold items-center ${classes.pagination}`}>{previousCommonIndex.length}</div>
										<button onClick={() => {getNext()}}  className={`md:w-24 flex justify-center py-2  items-center text-xl ${classes.pagination} ${canGoNext() ? `${classes.paginationHover} font-bold` : `` }`}>{`Next`}</button>
									</div>
								</div>

								<div className={`${classes.cardContent} h-auto flex-grow`}>
									{
										localState.history
											?
												<>
													{buildShelves()}
												</>
											: <></>
									}
								</div>
								<SocialsWithTermsAlt />
							</div>
						</div>
					</div>
					</AnimatePresence>
				</div>
			</div>

			<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`${classes.modal} w-full h-auto py-12 my-24 md:max-w-screen-lg md:mx-auto`}
        open={open.state}
				key={`1`}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open.state}>
					<div className={`h-ft ${classes.modalPopup} ${classes.modalBackground} w-full md:m-36 flex justify-center items-center flex-col`} >
						<div style={{backgroundColor: open.token.color}} className={`p-12`}>
							<div className={`py-12 text-4xl`}>{open.token.name}</div>
							<img src={open.token.image} className={`h-full md:mb-6`}/>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	)
}

import React, {useEffect, useState, useRef} from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { Link, animateScroll as scroll } from "react-scroll";

import {useHistory} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'
import logo from '../../assets/bottle_main.png'
import Wave from 'react-wavify'
import Minter from "../../components/minter_closed.js"

import MobileBottleMinter from '../../assets/main_interior_mobile.gif'
import Button from '../../assets/button.gif'
import Socials from "../../components/socials"


const useStyles = makeStyles(theme => ({
	heroBackground: {
		background: theme.body.heroBackground
	},
	gradientText: {
		backgroundImage: theme.text.rareLiquidGradient,
		background: theme.text.rareLiquidGradientWebkit,
		// fontSize: 55
	},
	mintButton: {
		zIndex: 10000,
		"&:hover": {
			transform: `scale(1.1)`
		}
	}
}),
{ index: 2 })

export default function Mint(props) {
	const classes = useStyles()
	let history = useHistory()

	const myRef = React.createRef()

	const [mintedCounts, setMintedCounts] = useState(null);

	const [localState, setLocalState] = useState({
		history: {}
	})

	useEffect(() => {
		setLocalState({
			history: props.history["web3Object"]
		})

	},[props.history["web3Object"]])


	const closePending = (id) => {
		props.history["web3Object"].closePending(id)
	}

	const updatePendingList = (id) => {
		props.history["web3Object"].setPendingList(id);
	}

	const closeTx = (id) => {
		props.history["web3Object"].closeTx(id);
	}

	return (
		<>
			<div className={` h-screen overflow-auto pb-24`} >
				{
					localState.history ?
						<>
							{
								localState.history.connected ?
									<>
										<Minter key={`minter`} status={true} contract={localState.history.nftContract} web3={localState.history.web3} currentAccount={localState.history.currentAccount} setPendingList={(e) => {updatePendingList(e)}}/>
									</>
								:
									<>
										<Minter key={`minter`} status={false} contract={null} web3={null} currentAccount={null} setPendingList={(e) => {updatePendingList(e)}}/>
									</>
							}
						</>
					:
						<>
							<Minter key={`minter`} status={false} contract={null} web3={null} currentAccount={null} setPendingList={(e) => {updatePendingList(e)}}/>
						</>
				}
				<Socials/ >
			</div>
		</>
	)
}

import React from 'react';
import { Provider } from 'react-redux'
import { hydrate, render } from 'react-dom';
import ReactDOM from 'react-dom';
import configureStore, { history } from './store/configureStore'
import { ConnectedRouter } from 'connected-react-router'


import './index.css';
import App from './App';

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App history={history} />
    </ConnectedRouter>

  </Provider>,

    document.getElementById('root')
);

import React, {useEffect, useState, useRef} from 'react'
import Countdown, { zeroPad }  from "react-countdown";

import {useHistory} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'
import logo from '../../assets/bottle_main.png'
import Wave from 'react-wavify'
import Minter from "../../components/minter.js"
import { history } from '../../store/configureStore'
import Socials from "../../components/socials"

import TwitterLink from "../../assets/twitterlink.png";
import DiscordLink from "../../assets/discord.png";
import MediumLink from "../../assets/med.png";
import SocialsWithTermsAlt from "../../components/socials"

const useStyles = makeStyles(theme => ({
	cardBackground: {
		backgroundColor: theme.body.basePageColorTrans,
		color: theme.text.primary,

	},
	gradientText: {
		backgroundImage: theme.text.rareLiquidGradient,
		background: theme.text.rareLiquidGradientWebkit,
		// fontSize: 55
	},
	pageHeight: {
		marginTop: '75px',
		height: 'calc(100vh - 75px)',
		overflow: "auto"
	},
	cardTitle: {
		fontSize: 40,
		fontWeight: 900
	},
	cardContent: {
		fontSize: 20,
		fontWeight: 900
	},
	cardContentSmall: {
		fontSize: 15,
	},
	cardContentMed: {
		fontSize: 20,
		fontWeight: 900
	}
}),
{ index: 2 })

export default function About(props) {
	const classes = useStyles()

	const myRef = React.createRef()



	const [localState, setLocalState] = useState({
		history: {}
	})

	useEffect(() => {
		setLocalState({
			history: props.history["web3Object"]
		})

	},[props.history["web3Object"]])


	const closePending = (id) => {
		props.history["web3Object"].closePending(id)
	}

	const updatePendingList = (id) => {
		props.history["web3Object"].setPendingList(id);
	}

	const closeTx = (id) => {
		props.history["web3Object"].closeTx(id);
	}



	return (
		<>
			<div className={`h-screen `} ref={myRef} >
				<div
					initial={{ opacity: 0, yScale: 0, y: 50 }}
					animate={{ opacity: 1, yScale: 1, y: 0 }}
					exit={{ opacity: 0, yScale: 0 }}
					transition={{ duration: 2 }}
					className={`h-auto w-full flex flex-col justify-start items-center relative`}
					style={{
						backgroundImage: `url(https://storage.googleapis.com/rareliquid-web/enter.gif)`,
						backgroundPosition: "bottom",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat"
					}}>
					<AnimatePresence
					>
					<div className={`${classes.pageHeight} w-full`}>
						<div className={` w-full md:max-w-screen-lg md:mx-auto md:mb-24`}>
							<div className={`${classes.cardBackground} md:mt-24 p-6 md:p-12`}>
								<div className={`${classes.cardTitle} pb-12`}>
									The Roadmap
								</div>
								<div className={`${classes.cardContent}`}>

								Timing and realization of roadmap activations depend on the success of the initial sale and our ability to rally our defiant degen community to spend more time discovering emerging artisans and coding than drinking.
								<br/><br/>
								Here’s what we’ve put on the back of the bar napkin so far:

									<ul className={`p-6 ${classes.cardContentSmall}`}>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>0%  </div>
										<div className={'flex flex-grow justify-start '}>We find something else to do. #next </div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>10%  </div>
										<div className={'flex flex-grow justify-start '}>Surprise asset airdrop for owners </div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>20%  </div>
										<div className={'flex flex-grow justify-start '}>Founding Artisan Reveal </div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>30%  </div>
										<div className={'flex flex-grow justify-start '}>Membership Upgrade Drop </div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>40%  </div>
										<div className={'flex flex-grow justify-start '}>Cold Storage Open </div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>50%  </div>
										<div className={'flex flex-grow justify-start '}>Charity Donation </div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>60%  </div>
										<div className={'flex flex-grow justify-start '}>Society Event </div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>70%  </div>
										<div className={'flex flex-grow justify-start '}>Baller Merch Drop </div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>80%  </div>
										<div className={'flex flex-grow justify-start '}>Rare Liquid Artisan Drop #2 (Hint: bubs) </div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>90%  </div>
										<div className={'flex flex-grow justify-start '}>Physical Redemption Open</div>
										</li>
										<li className={'flex justify-between text-xs md:text-base'}>
										<div className={'w-16 md:w-32'}>100% </div>
										<div className={'flex flex-grow justify-start '}>Open RL Index </div>
										</li>
									</ul>

									<div className={`${classes.cardContentMed} pt-12`}>

									Proceeds from the Rare Liquid NFT sale will fund platform development and a wide variety of exciting community activations.
									<br/><br/>
										Use of Proceeds
									</div>
									<ul className={`p-6 ${classes.cardContentSmall}`}>
										<li>- Community Treasury funding to begin sourcing Rare Liquids for the community and RL Index</li>
										<li>- Building out our membership and community features</li>
										<li>- Paying the bills we haven’t been paying </li>
										<li>- Philanthropy</li>
										<li>- RL Bottle Buy Back</li>
									</ul>
								</div>
								<SocialsWithTermsAlt />

							</div>
						</div>
					</div>
					</AnimatePresence>

				</div>
			</div>
		</>
	)
}

import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {isMobile} from 'react-device-detect';

import MobileBottleMinter from '../assets/main_interior_mobile.gif'
import Button from '../assets/button.gif'
import { motion, AnimatePresence } from 'framer-motion';

const useStyles = makeStyles(theme => ({
	nonItem: {
		color: theme.text.primary
	},
	body: {
		color: theme.text.primary
	},
	cardBackground: {
		backgroundColor: theme.body.cardBackground,
	},
	hoverable: {
		"&:hover": {
			color: theme.body.shelfBackgroundColorBlue
		}
	},
	mintButton: {
		zIndex: 100,
		"&:hover": {
			cursor: "pointer"
		}
	},
	screenTextMobile: {
		marginTop: 75,
		zIndex: 10,

		color: theme.text.primary
	},
	screenText: {
		fontWeight: 900
	},
	cardBackgroundTrans: {
		backgroundColor: theme.body.basePageColorTrans2,
		color: theme.text.primary,
	},
	cardBackgroundTransHead: {
		backgroundColor: theme.body.basePageColorTrans3,
		color: theme.text.primary,
	},
	adderText: {
		borderRadius: 1000,
		zIndex: 1000,
		overflow: "hidden"

	},
	plusTextBg: {
		borderRadius: 10,
		"&:hover": {
			backgroundColor: theme.body.shelfBackgroundColorBlueTrans,
			color: theme.body.shelfBackgroundColorBlueDark,
			fontWeight: 900

		}
	},
	minusTextBg: {
		borderRadius: 10,
		"&:hover": {
			backgroundColor: theme.body.shelfBackgroundColorPinkTrans,
			color: theme.body.shelfBackgroundColorPink,
			fontWeight: 900

		}
	},
	plusTextBgMobile: {
		borderRadius: 10,
		backgroundColor: theme.body.backgroundAccentLight,
		"&:hover": {
			backgroundColor: theme.body.shelfBackgroundColorBlueTrans,
			color: theme.text.primary,
			fontWeight: 900

		}
	},
	minusTextBgMobile: {
		borderRadius: 10,
		backgroundColor: theme.body.backgroundAccentLight,
		"&:hover": {
			backgroundColor: theme.body.shelfBackgroundColorPinkTrans,
			color: theme.text.primary,
			fontWeight: 900

		}
	},
	minHeight: {
		minHeight: 1300
	}
}),
{ index: 2 })

export default function Minter(props) {
  const classes = useStyles()
	const mintRef = React.createRef()
	const mintRefLarge = React.createRef()
	const mintRefMobile = React.createRef()
	let size = useWindowSize();

	const [totalSupply, setTotalSupply] = useState(null);
	const [error, setError] = useState(false);

	const [overrideSize, setOverrideSize] = useState({
		x: 550,
	})
	const [imageSize, setImageSize] = useState({
		x: null,
		y: 0
	})

	const [imageSizeLarge, setImageSizeLarge] = useState({
		screen: {
			x: null,
			y: 0
		},
		controlButtons: {
			x: null,
			y: 0
		},
		mintButton: {
			x: null,
			y: 0
		},
		x: null,
		y: 0
	})
  // MESSAGE QUEUE STUFF
  const [tokenM, setTokenM] = useState(1);
	const [errorList, setErrorList] = useState([])
	const price = .08;

	const addToQueue = () => {
    if (tokenM + 1 <= 20) {
      setTokenM(tokenM + 1)
    } else {
      alert("You cant mint that much")
    }
	}

	const removeFromQueue = () => {
			if (tokenM - 1 > 0) {
				setTokenM(tokenM - 1)
			}
	}

	const clearQueue = () => {
			setTokenM(1)
	}

	const executeOrder = async (amount, type) => {
	 	let bal = await props.web3.eth.getBalance(props.currentAccount)
		if (price * amount > bal) {
			setError(true)
			return
		}
		// console.log(props.web3.utils.toWei(parseFloat(price * amount).toPrecision(10).toString()/1e, "ether"))
		const amountToSend = props.web3.utils.toWei(parseFloat(price * amount).toPrecision(10).toString(), "ether"); // Convert to wei value

		let getData = props.contract.methods.mint(amount);
    let x = getData.encodeABI();

		// change price to amountToSend when not using 1 gwei
    let gas = await props.web3.eth.estimateGas({to: props.contract._address, data: x, value: amountToSend, from: props.currentAccount})
		let gasPrice = await props.web3.eth.getGasPrice()
		// type: "0x2", needs to be specified on rinkeby since envelope type was enabled in 1559
		// remove for mainnet and ganache tests type: "0x2",

		// console.log()
		// console.log(await props.web3.eth.getBlock("pending"))
		// let block = await props.web3.eth.getBlock("pending")
		let maxPriorityFeePerGas = await props.web3.eth.getMaxPriorityFeePerGas()

		props.web3.eth.sendTransaction({to: props.contract._address, from: props.currentAccount, data: x, type:"0x2", maxPriorityFeePerGas: maxPriorityFeePerGas, value: `${amountToSend}`, gas: `${Math.floor(gas*1.2)}`})
  		.on('transactionHash', (hash) => {
  			clearQueue(type)
        props.setPendingList(hash)
  		})
	}

  const getPrice = (amount, base) => {
    return (amount * base).toFixed(2)
  }

  const connect = () => {
		document.getElementById("_connector").click()
	}

	const getMintedSupply = () => {
		props.contract.methods.totalSupply().call().then((res) => {
			setTotalSupply(res)
		})
	}

	useEffect(() => {
		if (mintRef && mintRef.current && imageSize.x === null) {
			console.log(mintRef)
			setImageSize({
				x: mintRef.current.offsetWidth,
				y: mintRef.current.offsetHeight
			})
		}

	}, [mintRef])

	useEffect(() => {

		if (mintRef && mintRef.current ) {
			setImageSize({
				screen: {
					x: mintRef.current.offsetWidth,
					y: mintRef.current.offsetHeight
				},
				controlButtons: {
					x: mintRef.current.offsetWidth,
					y: mintRef.current.offsetHeight
				},
				mintButton: {
					x: mintRef.current.offsetWidth,
					y: mintRef.current.offsetHeight
				},
				x: mintRef.current.offsetWidth,
				y: mintRef.current.offsetHeight
			})
		}

	}, [size])

	useEffect(() => {
		if (mintRefLarge && mintRefLarge.current && imageSizeLarge.x === null ) {
			console.log("X NULL", imageSizeLarge)

			setImageSizeLarge({
				screen: {
					x: mintRef.current.offsetWidth,
					y: mintRef.current.offsetHeight
				},
				controlButtons: {
					x: mintRef.current.offsetWidth,
					y: mintRef.current.offsetHeight
				},
				mintButton: {
					x: mintRef.current.offsetWidth,
					y: mintRef.current.offsetHeight
				},
				x: mintRef.current.offsetWidth,
				y: mintRef.current.offsetHeight
			})
		}

	}, [size])

	useEffect(() => {
		if (mintRefLarge && mintRefLarge.current  ) {
			console.log("X SET", imageSizeLarge)

			setImageSizeLarge({
				x: mintRefLarge.current.offsetWidth,
				y: mintRefLarge.current.offsetHeight
			})
		}

	}, [size])



	useEffect(() => {
		if (props.status) {
			getMintedSupply()
		}
	}, [mintRef])

	useEffect(() => {
			if (mintRefLarge && mintRefLarge.current && imageSizeLarge.x === null) {
				console.log("IN USEEFFECT IN IF", mintRefLarge)

				setImageSizeLarge({
					screen: {
						x: mintRefLarge.current.offsetWidth,
						y: mintRefLarge.current.offsetHeight
					},
					controlButtons: {
						x: mintRefLarge.current.offsetWidth,
						y: mintRef.current.offsetHeight
					},
					mintButton: {
						x: mintRefLarge.current.offsetWidth,
						y: mintRef.current.offsetHeight
					},
					x: mintRefLarge.current.offsetWidth,
					y: mintRefLarge.current.offsetHeight
				})
			}

	}, [mintRefLarge])

	// style={{
	// 	backgroundImage: `url(${BottleMinter})`,
	// 	backgroundPosition: "bottom",
	// 	backgroundRepeat: "no-repeat"
	// }}
	return (
		<>
		{

			props.status
				?
					<>
						{
							isMobile ?
								<>
									<div
										 className={`${classes.screenTextMobile}  ${classes.cardBackgroundTrans} absolute flex flex-col justify-center items-center top-0 py-6`}
										 style={{
											 left: 0,
											 top: 25,
											 width: "100%"
										 }}
									 >
										 {
											 error ?
												 <>
													 You Don't Have Enough ETH!
												 </>
											 :
												 <>
													 <div className={`flex justify-center w-full font-bold`}>
														 {totalSupply} / 10,000
													 </div>

													 <div className={`flex justify-center w-full py-6 font-bold`}>
														 	<span className={`w-1/3 flex justify-center`}>Mint: {tokenM}</span>
														 	<span className={`mx-2 flex justify-center`}>=</span>
														 	<span className={`w-1/3 flex justify-center`}>{parseFloat(tokenM * .08).toPrecision(3) } ETH</span>
													 </div>

													 <div className={`flex justify-center w-full text-4xl `}>
														 <motion.button whileHover={{
														 	scale: 1.1,
														 	transition: { duration: .2 },
														 }}
														 onClick={() => {removeFromQueue()}}  className={`${classes.minusTextBgMobile} mx-1 w-12 h-12 flex items-center justify-center `}>
														 	-
														 </motion.button>
														 <motion.button whileHover={{
														 	scale: 1.1,
														 	transition: { duration: .2 },
														 }}
														 onClick={() => {addToQueue()}} className={`${classes.plusTextBgMobile} mx-1 w-12 h-12 flex items-center justify-center `}>
														 	+
														 </motion.button>
													 </div>
												 </>
										 }
									 </div>
									<button
										onClick={() => {executeOrder(tokenM)}}
										ref={mintRefMobile} id={`info_section`} className={`visible h-full w-full flex flex-col justify-end items-center relative `} style={{
											backgroundImage: `url(${MobileBottleMinter})`,
											backgroundSize: "cover",
											backgroundPosition: "bottom",
											backgroundRepeat: "no-repeat"
										}}>
										<AnimatePresence
											key={`animatedMintMobile`}
										>

										</AnimatePresence>
									</button>
								</>

							:
								<>
									<div ref={mintRef} id={`info_section`} className={`visible ${classes.minHeight} h-full min-h-screen w-full flex flex-col justify-start items-center bg-cover vlg:bg-contain  relative`} >

									<div
										 className={`${classes.screenTextMobile} ${classes.cardBackgroundTransHead}  absolute flex flex-col justify-center items-center top-0 py-6`}
										 style={{
											 left: 0,
											 top: 0,
											 width: "100%"
										 }}
									 >

										 <div className={`flex flex-col justify-center w-full`}>
											 <div className={`flex justify-center w-full font-bold text-4xl`}>
												{totalSupply} / 10,000
											 </div>

											 <div className={`flex justify-center w-full text-2xl py-2`}>
												Already Bottled!
											 </div>
											 <a href={`https://etherscan.io/token/0x2069f1F6a0202C095a2b6Ec4e15bF010e83b74ff`} target={`_blank`} className={`${classes.hoverable} flex justify-center w-full text-xl py-2`}>
												{props.contract._address}
											 </a>
										 </div>


									 </div>

									<div className={`pt-0 vlg:pt-24 relative w-full h-full flex justify-center items-start vlg:items-center`}>
										<img ref={el => {mintRefLarge.current = el;}} className={`absolute`} src={`https://storage.googleapis.com/rareliquid-web/main_interior.gif`} />

										<AnimatePresence
										 key={`animatedMintMain`}
										>
											<div
												initial={{ opacity: 0, y: 0 }}
												animate={{ opacity: 1 }}
												style={{
													width: imageSizeLarge.x ? (imageSizeLarge.x / 3.4) : overrideSize.x,
													height: imageSizeLarge.y ? (imageSizeLarge.y) : "100%",
												}}
												whileTap={{ scale: .97 }}
												className={`absolute flex flex-col justify-end items-end`}
											 >
												<div className={`relative `}>
													<div
														className={`${classes.screenText} top-0 text-xs md:text-sm lg:text-base absolute flex justify-center items-center w-full`}
														style={{
															paddingTop: imageSizeLarge.y ? (imageSizeLarge.y / 20) : 0,
														}}
													>
														{
															error ?
																<>
																	Not Enough ETH!
																</>
															:
																<>
																	<div className={`relative flex justify-between  text-xs lg:text-base`}
																		style={{
																			width: imageSizeLarge.x ? (imageSizeLarge.x / 8) : overrideSize.x,
																		}}

																	>
																	<span className={`invisible md:visible w-0 md:w-auto`}>Mint: {tokenM} = </span>
																	<span className={`visible md:invisible w-auto md:w-0`}>{tokenM} = </span>
																		<span className={`flex-grow flex justify-end`}>{parseFloat(tokenM * .08).toPrecision(2) } ETH</span>
																	</div>
																</>
														}


													</div>

													<div
														className={`${classes.adderText} text-sm md:text-lg lg:text-6xl font-bold absolute flex justify-center items-center left-0 flex`}
														style={{
															marginTop: imageSizeLarge.y ? (imageSizeLarge.y / 28) : 0,
															marginLeft: imageSizeLarge.x ? (imageSizeLarge.x / 200) : 0,
															width: imageSizeLarge.x ? (imageSizeLarge.x / 15) : overrideSize.x,
															height: imageSizeLarge.x ? (imageSizeLarge.x / 15) : (imageSizeLarge.x / 15)
														}}
													>
														<motion.button whileHover={{
															scale: 1.1,
															transition: { duration: .2 },
														}}
														onClick={() => {removeFromQueue()}}  className={`${classes.minusTextBg} w-1/2 h-full flex items-center justify-center `}>
															-
														</motion.button>
														<motion.button whileHover={{
															scale: 1.1,
															transition: { duration: .2 },
														}}
														onClick={() => {addToQueue()}} className={`${classes.plusTextBg} w-1/2 h-full flex items-center justify-center `}>
															+
														</motion.button>
													</div>

													<motion.img
														whileTap={{ scale: .97 }}

														whileHover={{
															scale: 1.1,
															transition: { duration: .2 },
														}}
														onClick={() => {executeOrder(tokenM)}}
														className={` ${classes.mintButton}`}
														width={`w-full`} src={Button} />
												</div>

											</div>
										</AnimatePresence>
									</div>


									</div>

								</>
							}
	          </>
	        :
						<>
							{
								isMobile ?
									<>
										<button
										onClick={() => {connect()}}
										ref={mintRefMobile} id={`info_section`} className={`visible h-full w-full flex flex-col justify-center items-center relative`} style={{
											backgroundImage: `url(${MobileBottleMinter})`,
											backgroundSize: "cover",
											backgroundPosition: "bottom",
											backgroundRepeat: "no-repeat"
										}}>
											<AnimatePresence
												key={`animatedMintMobile`}
											>
												<button
													initial={{ opacity: 0, y: 0 }}
													animate={{ opacity: 1 }}
													className={`fixed top-0 ${classes.cardBackgroundTrans}`}

												 >
												 <div
														className={`${classes.screenText} ${classes.screenTextMobile} absolute flex justify-center items-center`}
														style={{
															left: 0,
															top: 25,
															width: "100%"
														}}
													>
														Connect
													</div>
												</button>
											</AnimatePresence>
										</button>
									</>

								:
									<>
										<button ref={mintRef} id={`info_section`} className={`visible ${classes.minHeight} h-full min-h-screen w-full flex flex-col justify-start items-center bg-cover vlg:bg-contain  relative`}
										onClick={() => {connect()}}
										>

										<div
											 className={`${classes.screenTextMobile} ${classes.cardBackgroundTransHead}  absolute flex flex-col justify-center items-center top-0 py-6`}
											 style={{
												 left: 0,
												 top: 0,
												 width: "100%"
											 }}
										 >

											 <div className={`flex flex-col justify-center w-full`}>
												 <div className={`flex justify-center w-full font-bold text-4xl py-12`}>
													Click To Connect
												 </div>
											 </div>


										 </div>

										<div className={`relative w-full h-full flex justify-center items-center`} >
											<img  className={`absolute`} src={`https://storage.googleapis.com/rareliquid-web/main_interior.gif`} />

										</div>


										</button>
									</>
							}
					</>
      	}
		</>
	)
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

import config from '../config.js'

const doFetch = async ({url, method, body}) => {
	const options = {
		method,
		mode: 'cors',
		headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
		})
	}
	if(body) {
		options.body = await JSON.stringify(body)
	}

	try {
		let res = await fetch(url, options)
		console.log("RES", res)
		//console.log('HTTPS response status: ', res.status, res, options)

		return res.status === 204 || res.status === 404 || res.status === 500
			? res
			: res.json()

	} catch(error) {
		console.error(error)
	}
}


export const getMetadata = async(token) => {
	const url = `https://tokens.rareliquid.fi/metadata/${token}`
	const method = 'get'
	const response = await doFetch({url, method})
	return response
}

import baseTheme from './base'
import PoiretOne from "../assets/fonts/poiret-one/PoiretOne-Regular.ttf";

// main
const basePageColor = "#ffffff"
const backgroundAccent = "#fbfbfb"
const borderAccent = "#f2f7f9"

const rareLiquidGradient = "linear-gradient(45deg, #72c1cc, #dd529b)"
const rareLiquidGradientWebkit = "  background: -webkit-linear-gradient(#72c1cc, #dd529b)"



// OLD STUFF
const colorAccent = '#1c8b95'
const colorSecondary = '#9AFFFD'

const textPrimary = '#000000'
const textSecondary = '#909090'
const textTertiary = '#dcdcdc'
const textLowlight = '#000000'
const lightRed = '#ff5f5f';

const accent = `#274060`
const accent2 = `#22252d`
const accentRed = `#E71D36`
const accentGreen = `#00F0B5`
// F7FF58
const accentPrimaryl = `${accent}`
const accentPrimaryl0 = `${accent}11`
const accentPrimaryl1 = `${accent}31`
const accentPrimaryl2 = `${accent}64`
const accentPrimaryl3 = `${accent}94`
const accentPrimaryl4 = `${accent}ff`

const accent2Primaryl = `${accent2}`
const accent2Primaryl0 = `${accent2}24`
const accent2Primaryl1 = `${accent2}44`
const accent2Primaryl2 = `${accent2}64`
const accent2Primaryl3 = `${accent2}84`
const accent2Primaryl4 = `${accent2}ff`

const accentRedl2 = `${accentRed}64`
const accentRedl4 = `${accentRed}ff`

const whiteAccent = `#dcdcdc`
const radialBackgroundUpperLeft = `#041323`
const radialBackgroundLowerRight = `#040623`

const colorl = `${colorAccent}`
const colorl0 = `${colorAccent}24`
const colorl1 = `${colorAccent}44`
const colorl2 = `${colorAccent}64`
const colorl3 = `${colorAccent}84`
const colorl4 = `${colorAccent}ff`

const bgColor1 = '#000000'
const bgColor3 = '#3F4456'
const bgColor4 = '#3B4053'
const bgColor5 = '#171D31'
const accentTrans = `${colorSecondary}64`
const accentTrans2 = `${colorSecondary}84`

export const textFont = {
	fontFamily: 'PoiretOne',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `
    local('PoiretOne'),
    local('PoiretOne'),
    url(${PoiretOne}) format('truetype')
  `,
}
baseTheme.overrides["MuiCssBaseline"] = {
	'@global': {
		'@font-face': [textFont],

		body: {
			backgroundColor: basePageColor,
			// background: `linear-gradient(315deg,#050817, #030b15)`,
			// backgroundRepeat: "no-repeat",
			// backgroundAttachment: "fixed",
			// "&:after": {
			//  position: "absolute",
			//  content: "''",
			//  top: -250,
			//  left: -250,
			//  width: 1500,
			//  height: 1000,
			//  background: `radial-gradient(${radialBackgroundUpperLeft}, transparent, transparent)`,
			// },
			// "&:before": {
			//  position: "absolute",
			//  content: "''",
			//  bottom: -250,
			//  right: -250,
			//  width: 1000,
			//  height: 1500,
			//  background: `radial-gradient(${radialBackgroundLowerRight}, transparent, transparent)`,
			// }
		}
	}
}
const theme = {
	...baseTheme,

	palette: {
		...baseTheme.palette,
		background: {
			default: basePageColor
		},
		primary: {
			...baseTheme.palette.primary,
			main: colorAccent,
		},
		secondary: {
			...baseTheme.palette.secondary,
			main: colorSecondary,
		}
	},
	body: {
		basePageColor: basePageColor,
		backgroundAccent: backgroundAccent,
		rareLiquidGradientTrans: `linear-gradient(45deg, #91f2ff14, #dd529b14)`,
		borderAccent: borderAccent,

		accentTrans: accentTrans,
		darkAccent: accent,

		accentPrimaryl: accentPrimaryl,
		accentPrimaryl0: accentPrimaryl0,
		accentPrimaryl1: accentPrimaryl1,
		accentPrimaryl2: accentPrimaryl2,
		accentPrimaryl3: accentPrimaryl3,
		accentPrimaryl4: accentPrimaryl4,

		accent2Primaryl: accent2Primaryl,
		accent2Primaryl0: accent2Primaryl0,
		accent2Primaryl1: accent2Primaryl1,
		accent2Primaryl2: accent2Primaryl2,
		accent2Primaryl3: accent2Primaryl3,
		accent2Primaryl4: accent2Primaryl4,

		colorl: colorl,
		colorl0: colorl0,
		colorl1: colorl1,
		colorl2: colorl2,
		colorl3: colorl3,
		colorl4: colorl4,
		lightAccent: basePageColor,
		whiteAccent: whiteAccent,
		accentGreen: accentGreen,
		accentGreen4: accentGreen,

		accentRed: accentRed,
		accentRedl2: accentRedl2,
		accentRedl4: accentRedl4,
		colorAccent: colorAccent
	},
	border: {},
	buttons: {
		...baseTheme.buttons,
		outlined: {
			...baseTheme.buttons.outlined,
			default: {
				...baseTheme.buttons.outlined.default,
				color: textPrimary,
			}
		}
	},
	card: {
		header: {
			backgroundColor: bgColor5,
			color: textSecondary,
		}
	},
	environments: {
		...baseTheme.environments,
		card: {
			backgroundColor: '#3C4153',
		},
	},
	imageUploader: {
		backgroundColor: bgColor3,
	},
	modal: {
		backgroundColor: bgColor1,
	},
	overrides: {
		...baseTheme.overrides,
		MuiCheckbox: {
			...baseTheme.overrides.MuiCheckbox,
			colorSecondary: {
				'&.Mui-disabled': {
					color: 'rgba(255,255,255,0.25)'
				},
			},
			root: {
				...baseTheme.overrides.MuiCheckbox.root,
				color: textPrimary
			}
		},
		MuiFormControlLabel: {
			label: {
				'&.Mui-disabled': {
					color: textSecondary
				}
			}
		},
		MuiFormHelperText: {
			root: {
				color: textSecondary,
				'&.Mui-error': {
					color: colorAccent,
				}
			},
		},
		MuiFormLabel: {
			root: {
				color: textPrimary,
				'&.Mui-disabled': {
					color: textSecondary,
				},
				'&.Mui-focused': {
					color: colorSecondary,
				},
				'&.Mui-error': {
					color: colorAccent,
				},
			},
		},
		MuiIconButton: {
			root: {
				color: textSecondary,
			},
		},
		MuiInput: {
			underline: {
				'&:before': {
					borderBottomColor: textSecondary,
				},
				'&:after': {
					borderBottomColor: textSecondary,
				},
				'&:hover:before': {
					borderBottomColor: [textSecondary, '!important'],
				},
				'&.Mui-error:after': {
					borderBottomColor: colorAccent,
				},
			},
			root: {
				color: textPrimary,
				'&:hover': {
					borderBottom: 'none'
				},
				'& .Mui-disabled': {
					color: textPrimary,
				},
			},
		},
		MuiInputAdornment: {
			root: {
				color: textPrimary,
			}
		},
		MuiPaper: {
			root: {
				backgroundColor: bgColor1,
				color: textPrimary,
			}
		},
		MuiPickersDay: {
			day: {
				color: textPrimary,
			},
		},
		MuiPickersCalendarHeader: {
			dayLabel: {
				color: textSecondary,
			},
			iconButton: {
				backgroundColor: 'none'
			}
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: bgColor5,
			}
		},
		MuiPopover: {
			paper: {
				backgroundColor: bgColor1,
			},
		},
		MuiSelect: {
			icon: {
				color: textPrimary
			}
		},
		MuiTypography: {
			colorTextSecondary: {
				color: textSecondary
			}
		}
	},
	select: {
		backgroundColor: bgColor4,
	},
	table: {
		backgroundColor: 'transparent',
		actions: {
			color: '#C9C9C9'
		},
		header: {},
		highlight: {
			backgroundColor: bgColor5
		},
		rows: {
			backgroundColor: bgColor5
		}
	},
	tabs: {
		indicator: colorAccent,
		buttonTabs: {
			root: {
				backgroundColor: bgColor4,
				color: '#666'
			},
			selected: {
				backgroundColor: '#DADBDF'
			}
		}
	},
	text: {
		primary: textPrimary,
		secondary: textSecondary,
		rareLiquidGradient: rareLiquidGradient,
		rareLiquidGradientWebkit: rareLiquidGradientWebkit
	},
}

export default theme

import React, {useEffect, useState, useRef} from 'react'
import Countdown, { zeroPad }  from "react-countdown";

import {useHistory} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'
import logo from '../../assets/bottle_main.png'
import Wave from 'react-wavify'
import Minter from "../../components/minter.js"
import { history } from '../../store/configureStore'

import TwitterLink from "../../assets/twitterlink.png";

import OXAB from "../../assets/team/0xabb.png";
import OXPEATY from "../../assets/team/0xpeaty.png";
import Cryptosomm from "../../assets/team/Cryptosomm.jpeg";
import Lex from "../../assets/team/lex.png";
import MythLab from "../../assets/team/MythLab.png";
import Papi from "../../assets/team/papi.png";
import TheFrench from "../../assets/team/TheFrench.png";
import Typsy from "../../assets/team/typsy.jpeg";
import NeonBuffalo from "../../assets/team/NeonBuffalo.jpg";
import SocialsWithTermsAlt from "../../components/socials"
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles(theme => ({
	cardBackground: {
		backgroundColor: theme.body.basePageColorTrans,
		color: theme.text.primary,
		fontWeight: 900
	},
	gradientText: {
		backgroundImage: theme.text.rareLiquidGradient,
		background: theme.text.rareLiquidGradientWebkit,
		// fontSize: 55
	},
	pageHeight: {
		marginTop: '75px',
		height: 'calc(100vh - 75px)',
		overflow: "auto"
	},
	cardTitle: {
		fontSize: 40,
		fontWeight: 900
	},
	cardContent: {
		fontSize: 20,
		fontWeight: 500
	},
	cardContentSmall: {
		fontSize: 15,
	},
	cardContentSmallBold: {
		fontSize: 15,

	},
	cardContentMed: {
		fontSize: 20,
		fontWeight: 900

	},
	teamImage: {
		borderRadius: 10,
		width: 250,
		height: 250
	},
	sommBackground: {
		backgroundColor: "#e1e3a6"
	},
	link: {
		color: theme.text.primary,
		width: 20,
		height: 20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}
}),
{ index: 2 })

export default function About(props) {
	const classes = useStyles()

	const myRef = React.createRef()



	const [localState, setLocalState] = useState({
		history: {}
	})

	useEffect(() => {
		setLocalState({
			history: props.history["web3Object"]
		})

	},[props.history["web3Object"]])


	const closePending = (id) => {
		props.history["web3Object"].closePending(id)
	}

	const updatePendingList = (id) => {
		props.history["web3Object"].setPendingList(id);
	}

	const closeTx = (id) => {
		props.history["web3Object"].closeTx(id);
	}






	return (
		<>
			<div className={`h-screen `} ref={myRef} >
				<div
					initial={{ opacity: 0, yScale: 0, y: 50 }}
					animate={{ opacity: 1, yScale: 1, y: 0 }}
					exit={{ opacity: 0, yScale: 0 }}
					transition={{ duration: 2 }}
					className={`h-auto w-full flex flex-col justify-start items-center relative`}
					style={{
						backgroundImage: `url(https://storage.googleapis.com/rareliquid-web/enter.gif)`,
						backgroundPosition: "bottom",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat"
					}}>
					<AnimatePresence
					>
					<div className={`${classes.pageHeight} w-full`}>
						<div className={` w-full md:max-w-screen-lg md:mx-auto md:mb-24`}>
						<div className={`${classes.cardBackground} md:mt-24 p-6 md:p-12`}>
								<div className={`${classes.cardTitle} pb-12`}>
									The Team
								</div>
								<div className={`${classes.cardBackground} md:p-12 flex flex-col md:flex-row justify-between`}>
									<div id={`info_section`} className={`${classes.teamImage}`} style={{
										backgroundImage: `url(${OXPEATY})`,
										backgroundPosition: "bottom",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat"
									}}></div>
									<div className={`w-full md:w-1/2 py-4 md:py-0`}>
										<ul>
											<li className={`${classes.cardContentMed}  flex items-center`}>
												<div>0XPeaty</div>
												<a href="https://twitter.com/0xpeaty" target="_blank" className={`${classes.link}  mx-2`}>
													<img src={TwitterLink} className={"social-icons"} />
												</a>
											</li>
											<li>Co-Founder</li>
											<li className={`pt-4 flex flex-col`}>
											<div className={`${classes.cardContentSmallBold}`}>Sips and crunches on:</div>
												<div>Islay scotch and salty pickles</div>
											</li>
										</ul>
									</div>
								</div>
								<div className={`${classes.cardBackground} md:p-12 flex flex-col md:flex-row justify-between`}>
									<div id={`info_section`} className={`${classes.teamImage}`} style={{
										backgroundImage: `url(${Papi})`,
										backgroundPosition: "bottom",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat"
									}}></div>
									<div className={`w-full md:w-1/2 py-4 md:py-0`}>
										<ul>
											<li className={`${classes.cardContentMed} flex items-center`}>
												<div>Papi Van Winkle Voss</div>
												<a href="https://twitter.com/PWinklevoss" target="_blank" className={`${classes.link}  mx-2`}>
													<img src={TwitterLink} className={"social-icons"} />
												</a>
											</li>
											<li>Co-Founder</li>
											<li className={`py-4 flex flex-col`}>
												<div className={`${classes.cardContentSmallBold}`}>Relaxes with a:</div>
												<div>Whiskey Manhattan (from a coffee cup) and carnitas tacos (corn tortillas must be fresh)</div>
											</li>
										</ul>
									</div>
								</div>
								<div className={`${classes.cardBackground} md:p-12 flex flex-col md:flex-row justify-between`}>
									<div id={`info_section`} className={`${classes.teamImage}`} style={{
										backgroundImage: `url(${OXAB})`,
										backgroundPosition: "bottom",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat"
									}}></div>
									<div className={`w-full md:w-1/2 py-4 md:py-0`}>
										<ul>
											<li className={`${classes.cardContentMed}  flex items-center`}>
												<div>0Xeeeb</div>
												<a href="https://twitter.com/0Xeeeb" target="_blank" className={`${classes.link}  mx-2`}>
													<img src={TwitterLink} className={"social-icons"} />
												</a>
											</li>
											<li>Founding Dev</li>
											<li className={`pt-4 flex flex-col`}>
											<div className={`${classes.cardContentSmallBold}`}>Chills out with a:</div>
												<div>Black Manhattan and some Pretzels</div>
											</li>
										</ul>
									</div>
								</div>
								<div className={`${classes.cardBackground} md:p-12 flex flex-col md:flex-row justify-between`}>
									<div id={`info_section`} className={`${classes.teamImage}`} style={{
										backgroundImage: `url(${Lex})`,
										backgroundPosition: "bottom",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat"
									}}></div>
									<div className={`w-full md:w-1/2 py-4 md:py-0`}>
										<ul>
											<li className={`${classes.cardContentMed}  flex items-center`}>
												<div>Lex Cursor</div>
												<a href="https://twitter.com/" target="_blank" className={`${classes.link}  mx-2`}>
													<img src={TwitterLink} className={"social-icons"} />
												</a>
											</li>
											<li>Founding Counsel</li>
											<li className={`pt-4 flex flex-col`}>
											<div className={`${classes.cardContentSmallBold}`}>Kicks back and enjoys:</div>
												<div>Old vine zin and a warm chocolate old-fashioned doughnut</div>
											</li>
										</ul>
									</div>
								</div>
								<div className={`${classes.cardBackground} md:p-12 flex flex-col md:flex-row justify-between`}>
									<div id={`info_section`} className={`${classes.teamImage}`} style={{
										backgroundImage: `url(${Typsy})`,
										backgroundPosition: "bottom",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat"
									}}></div>
									<div className={`w-full md:w-1/2 py-4 md:py-0`}>
										<ul>
											<li className={`${classes.cardContentMed} flex items-center`}>
												<div>Typsy Turtle</div>
												<a href="https://twitter.com/turtlecryp" target="_blank" className={`${classes.link}  mx-2`}>
													<img src={TwitterLink} className={"social-icons"} />
												</a>
											</li>
											<li>Founding Artisan- Winemaker</li>
											<li className={`pt-4 flex flex-col`}>
											<div className={`${classes.cardContentSmallBold}`}>À ta santé:</div>
												<div>Chateauneuf-du-Pape and In N Out</div>
											</li>
										</ul>
									</div>
								</div>
								<div className={`${classes.cardBackground} md:p-12 flex flex-col md:flex-row justify-between`}>
									<div id={`info_section`} className={`${classes.teamImage} ${classes.sommBackground}`} style={{
										backgroundImage: `url(${Cryptosomm})`,
										backgroundPosition: "bottom",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat"
									}}></div>
									<div className={`w-full md:w-1/2 py-4 md:py-0`}>
										<ul>
											<li className={`${classes.cardContentMed} flex items-center`}>
												<div>Cryptosomm</div>
												<a href="https://twitter.com/Cryptosomm" target="_blank" className={`${classes.link}  mx-2`}>
													<img src={TwitterLink} className={"social-icons"} />
												</a>
											</li>
											<li>Founding Sommelier</li>
											<li className={`pt-4 flex flex-col`}>
											<div className={`${classes.cardContentSmallBold}`}>Munches and sips:</div>
												<div>Caviar on Pringles with Bubs</div>
											</li>
										</ul>
									</div>
								</div>

								<div className={`${classes.cardBackground} md:p-12 flex flex-col md:flex-row justify-between`}>
									<div id={`info_section`} className={`${classes.teamImage}`} style={{
										backgroundImage: `url(${TheFrench})`,
										backgroundPosition: "bottom",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat"
									}}></div>
									<div className={`w-full md:w-1/2 py-4 md:py-0`}>
										<ul>
											<li className={`${classes.cardContentMed} flex items-center`}>
												<div>TheFrench</div>
												<a href="https://twitter.com/TheFren90377194" target="_blank" className={`${classes.link}  mx-2`}>
													<img src={TwitterLink} className={"social-icons"} />
												</a>
											</li>
											<li>Founding EU Curator</li>
											<li className={`pt-4 flex flex-col`}>
											<div className={`${classes.cardContentSmallBold}`}>Sits back with:</div>
												<div>Cognac & dark chocolate</div>
											</li>
										</ul>
									</div>
								</div>

								<div className={`${classes.cardBackground} md:p-12 flex flex-col md:flex-row justify-between`}>
									<div id={`info_section`} className={`${classes.teamImage}`} style={{
										backgroundImage: `url(${MythLab})`,
										backgroundPosition: "bottom",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat"
									}}></div>
									<div className={`w-full md:w-1/2 py-4 md:py-0`}>
										<ul>
											<li className={`${classes.cardContentMed} flex items-center`}>
												<div>Myth Lab</div>
												<a href="https://twitter.com/themythlab" target="_blank" className={`${classes.link}  mx-2`}>
													<img src={TwitterLink} className={"social-icons"}  />
												</a>
												<a href="http://www.themythlab.co.uk" target="_blank" className={`${classes.link} `}>
													<LanguageIcon />
												</a>

											</li>
											<li>Founding Artist</li>
											<li className={`pt-4 flex flex-col`}>
											<div className={`${classes.cardContentSmallBold}`}>Unwinds with a huge:</div>
												<div>Pulled Pork Mac & Cheese Burger, and several strong IPAs</div>
											</li>
										</ul>
									</div>
								</div>

								<div className={`${classes.cardBackground} md:p-12 flex flex-col md:flex-row justify-between`}>
									<div id={`info_section`} className={`${classes.teamImage}`} style={{
										backgroundImage: `url(${NeonBuffalo})`,
										backgroundPosition: "bottom",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat"
									}}></div>
									<div className={`w-full md:w-1/2 py-4 md:py-0`}>
										<ul>
											<li className={`${classes.cardContentMed} flex items-center`}>
												<div>Neon Buffalo</div>
												<a href="https://twitter.com/neon_buffalo" target="_blank" className={`${classes.link}  mx-2`}>
													<img src={TwitterLink} className={"social-icons"} />
												</a>
											</li>
											<li>Founding Artist</li>
											<li className={`pt-4 flex flex-col`}>
											<div className={`${classes.cardContentSmallBold}`}>Finds tranquility with a:</div>
												<div>Green Chili Breakfast Burrito and Dr. Pepper</div>
											</li>
										</ul>
									</div>
								</div>

								<SocialsWithTermsAlt />

							</div>

						</div>
					</div>

					</AnimatePresence>

				</div>
			</div>
		</>
	)
}

import React, {useEffect, useState, useRef} from 'react'
import Countdown, { zeroPad }  from "react-countdown";
import {isMobile} from 'react-device-detect';

import {useHistory} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'
import logo from '../../assets/bottle_main.png'
import Wave from 'react-wavify'
import Minter from "../../components/minter.js"
import SocialsWithTerms from "../../components/socialswithtermshome"

import { history } from '../../store/configureStore'

import Crest from '../../assets/crest_shadow_v2.png'

const useStyles = makeStyles(theme => ({
	heroBackground: {
		background: theme.body.heroBackground
	},

	gradientText: {
		// backgroundImage: theme.text.rareLiquidGradient,
		// background: theme.text.rareLiquidGradientWebkit,
		// fontSize: 55
	},
	smallCard: {
		width: 200,
		fontSize: '25px !important'
	},
	largeCard: {
		fontSize: '55px !important'

	},
	learnMoreButton: {
		color: theme.text.primary,

		"&:hover": {
			cursor: "pointer",
			color: theme.text.primary,
		}
	},
	parallaxBackground: {
		backgroundColor: theme.body.backgroundAccent,
	},
	waveFill: {
		fill: theme.body.backgroundAccent,
		top: -150,
		zIndex: 100000,
	},
	cardBackground: {
		// backgroundColor: theme.body.basePageColorTrans2,
		color: theme.text.primary,
		marginTop: 150
	},
	enterButton: {
		zIndex: 10000,
		fontSize: 35,
		fontWeight: 900,
		color: "black"
	},
	enterButtonMobile: {
		zIndex: 10000,
		fontSize: 35,
		fontWeight: 900,
		color: "white"
	},
	countdown: {
		zIndex: 10000,
		fontWeight: 900,
		color: theme.text.primary
	},

	tilt: {
		transform: `rotate3d(-5, 5, 5, 25deg)`
	},
	size: {
		overflow: "hidden"
	},
	noPadding: {
		padding: "0 !important"
	}
}),
{ index: 2 })
// parseInt(process.env.REACT_APP_COUNTDOWN_TO)
export default function Home(props) {
	const classes = useStyles()

	const myRef = React.createRef()
	const enterRef = React.createRef()
	const enterRefMobile = React.createRef()
	const size = useWindowSize();
	const [overrideSize, setOverrideSize] = useState({
		x: 200,
		offset: 9
	})

	const [imageSize, setImageSize] = useState({
		x: null,
		y: 0
	})

	const [mintedCounts, setMintedCounts] = useState(null);

	const [localState, setLocalState] = useState({
		history: {}
	})

	useEffect(() => {
		setLocalState({
			history: props.history["web3Object"]
		})

	},[props.history["web3Object"]])


	const closePending = (id) => {
		props.history["web3Object"].closePending(id)
	}

	const updatePendingList = (id) => {
		props.history["web3Object"].setPendingList(id);
	}

	const closeTx = (id) => {
		props.history["web3Object"].closeTx(id);
	}

	useEffect(() => {
		if (enterRef && enterRef.current && imageSize.x === null) {
			setImageSize({
				x: enterRef.current.offsetWidth,
				y: enterRef.current.offsetHeight
			})
		}

	}, [enterRef])

	useEffect(() => {
		if (size.width > 1000 && overrideSize.x !== 200) {
			setOverrideSize({
				x: 200,
				offset: 4
			})
		}

		if (size.width < 1000 && overrideSize.x === 200) {
			setOverrideSize({
				x: 200,
				offset: 9
			})
		}

		if (enterRef && enterRef.current ) {
			setImageSize({
				x: enterRef.current.offsetWidth,
				y: enterRef.current.offsetHeight
			})
		}

	}, [size])


	const Completionist = () =>
		<AnimatePresence>
			<motion.a
				href={`https://mint.rareliquid.fi/mint`}
				whileHover={{
				  scale: 1.1,
				  transition: { duration: .2 },
				}}
				className={``}
			>
				<img className={`${classes.tilt} mt-20 md:mt-0`} src={Crest} width={overrideSize.x}/>
				<div className={`${isMobile ? classes.enterButtonMobile : classes.enterButton} flex justify-center`}>Enter</div>
			</motion.a>
		</AnimatePresence>

		;

	const renderer = ({days, hours, minutes, seconds, completed }) => {
	  if (completed) {
	    // Render a completed state
	    return <Completionist />;
	  } else {
	    // Render a countdown
	    return(
			<>
				<div className={`flex flex-col justify-center items-center`}>
					<div className={`${classes.countdown} text-4xl md:text-6xl w-full mt-24 md:mt-32 justify-start flex`}>
						<span className={'flex-grow'}>{zeroPad(days)}</span>
						<span className={'flex-grow'}>:</span>
						<span className={'flex-grow'}>{zeroPad(hours)}</span>
						<span className={'flex-grow'}>:</span>
						<span className={'flex-grow'}>{zeroPad(minutes)}</span>
						<span className={'flex-grow'}>:</span>
						<span className={'flex-grow'}>{zeroPad(seconds)}</span>
					</div>


				</div>

			</>)
			;
	  }
	};

	return (
		<>

			{
				isMobile || size.width < 700 ?
					<>
						<div
							ref={enterRefMobile}
							initial={{ opacity: 0, yScale: 0, y: 50 }}
							animate={{ opacity: 1, yScale: 1, y: 0 }}
							exit={{ opacity: 0, yScale: 0 }}
							transition={{ duration: 2 }}
							className={`visible h-full w-full flex flex-col justify-center items-center relative ${classes.size} p-6`} style={{
								backgroundImage: `url(https://storage.googleapis.com/rareliquid-web/enter.gif)`,
								backgroundSize: "cover",
								backgroundPosition: "bottom",
								backgroundRepeat: "no-repeat"
							}}>
							<AnimatePresence
							>
								<motion.a
									initial={{ opacity: 0, y: 0 }}
									animate={{ opacity: 1 }}
								  whileTap={{ scale: 1.15 }}

									key={`animatedMobile`}
									className={`fixed ${classes.cardBackground} ${classes.smallCard} flex justify-center items-center top-0  p-4 items-center h-36`}
									href={`https://mint.rareliquid.fi/mint`}
								 >
									<Countdown date={parseInt(process.env.REACT_APP_COUNTDOWN_TO)} renderer={renderer} zeroPadTime={2} />
								</motion.a>
							</AnimatePresence>
						</div>
						<SocialsWithTerms/ >
					</>
				:
					<>
						<div
							ref={enterRef}
							initial={{ opacity: 0, yScale: 0, y: 50 }}
							animate={{ opacity: 1, yScale: 1, y: 0 }}
							exit={{ opacity: 0, yScale: 0 }}
							transition={{ duration: 2 }}
							className={`h-full w-full flex flex-col justify-start items-center relative`}
							style={{
								backgroundImage: `url(https://storage.googleapis.com/rareliquid-web/enter.gif)`,
								backgroundPosition: "bottom",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat"
							}}>
							<AnimatePresence>
								<motion.div
									initial={{ opacity: 0, y: 0 }}
									animate={{ opacity: 1 }}
									style={{
										left: `${(imageSize.x/2) - overrideSize.x/2}px`,
										top: (imageSize.y/7 * 1.5) - overrideSize.x/overrideSize.offset,
										width: 300,
									}}
									key={`animatedMain`}
									className={`fixed flex justify-center items-center ${classes.countdownBackground} ${classes.largeCard}`}
								 >
									<Countdown date={parseInt(process.env.REACT_APP_COUNTDOWN_TO)} renderer={renderer} zeroPadTime={2} />
								</motion.div>
							</AnimatePresence>
						</div>
						<SocialsWithTerms/ >

					</>
			}
		</>
	)
}


// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

import React, {useEffect, useState} from 'react'
import CssBaseline from "@material-ui/core/CssBaseline";
import {isMobile} from 'react-device-detect';

import logo from './logo.svg';
import {connect} from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Route, Router, Switch, Redirect } from 'react-router-dom'
import {withRouter} from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import getTheme from './themes'

import Web3 from "web3";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

import Home from "./pages/home"
import Mint from "./pages/mint"
import Wallet from "./pages/wallet"
import About from "./pages/about"
import Roadmap from "./pages/roadmap"
import FAQs from "./pages/faqs"
import Team from "./pages/team"

import Navbar from "./components/navbar"
import TransactionWidget from "./components/TransactionWidget";


let RLSContractAddress;
let RLSContractABI;
import(
	'./contracts/RLS.json'
	).then(({default: json}) => {
		RLSContractABI = (json.abi)
		//RLSContractAddress = (json.networks[process.env.NODE_ENV === "production" ? 1 : 5777].address)
		RLSContractAddress = (json.networks[process.env.NODE_ENV === "production" ? 1 : 1].address)
});

const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider, // required
		options: {
			infuraId: "65f4039651654a9c9ce764f6482a2016" // required
		},
		rpc: {
			1: `https://eth-mainnet.alchemyapi.io/v2/5NfiyizwPdTlddi9z7omq6NyOap1J_OY`,
			4: `https://eth-rinkeby.alchemyapi.io/v2/KK-2VD26jwloEmJ7TFdmz9mon7bLa6z9`
		}
	}
};

const INITIAL_STATE = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: process.env.NODE_ENV === "production" ? 1 : 1,
  networkId: process.env.NODE_ENV === "production" ? 1 : 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null
};

function initWeb3(provider: any) {
  // const web3: any = new Web3(provider);
	console.log(provider)
	const web3 = createAlchemyWeb3(process.env.NODE_ENV === "production"
		?
			`https://eth-mainnet.alchemyapi.io/v2/5NfiyizwPdTlddi9z7omq6NyOap1J_OY`
		:
			`https://eth-mainnet.alchemyapi.io/v2/5NfiyizwPdTlddi9z7omq6NyOap1J_OY`);

	web3.setWriteProvider(provider)

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });

  return web3;
}

const App = ({ history }) => {
	if (!isMobile) {
		localStorage.setItem("WEB3_CONNECT_CACHED_PROVIDER", `"injected"`)
  }

  const setupTheme = () => {
    localStorage.setItem("rl_theme", "DARK")
    return "DARK"
  }

	const [appTheme, setAppTheme] = useState(getTheme(localStorage.getItem("rl_theme") || setupTheme()))
	const [connectedPreviously, setConnectedPreviously] = useState(localStorage.getItem("connected_previously") || 'no')

	const _web3Modal = new Web3Modal({
		network: process.env.NODE_ENV === "production" ? "mainnet" : "mainnet", // optional
		cacheProvider: true, // optional
		providerOptions // required
	});

	const [pendingList, setPendingList] = useState([])
	const [activePage, setActivePage] = useState('home')
	const [messageList, setMessageList] = useState([])
  const [web3Modal, setWeb3Modal] = useState(_web3Modal)
  const [state, setState] = useState({
    web3: null,
    provider: null,
    connected: false,
    chainId: 1,
    networkId: 1,
    showModal: false,
    pendingRequest: false,
    result: null,
    fetching: false,
    address: ""
  });

  const modalConnect = async () => {
		return await web3Modal.connect();
	}

	useEffect(() => {
	},[pendingList, messageList])

	useEffect(() => {
		if (activePage !== history.location.pathname.substring(1)) {
			setActivePage(history.location.pathname.substring(1))
		}
	},[history])

	const updatePage = (page) => {
		if (activePage !== page) {
			setActivePage(page)
		}
	}

	useEffect(() => {
		if (connectedPreviously === 'yes') {
			onConnect()
		}

	},[web3Modal])

	useEffect(() => {
		if (isMobile) {
			// onConnect();
			modalConnect().then((provider) => {
				console.log("CONNECTING")
				return new Web3(provider)
			}).then((web3) => {
				console.log("WEB3: ", web3)

				if(web3Modal.cachedProvider) {
					console.log("cachedProvider: ", web3Modal.cachedProvider)

				}
			});
		}
	},[web3Modal])


  const onConnect = async () => {
		console.log("IN ONCONNECT")
		const provider = await web3Modal.connect();
		await subscribeProvider(provider);
		localStorage.setItem("connected_previously", "yes")

		const web3: any = initWeb3(provider);
		const accounts = await web3.eth.getAccounts();
		const address = accounts[0];

		const networkId = await web3.eth.net.getId();
		const chainId = await web3.eth.chainId();
		const nftContract = new web3.eth.Contract(RLSContractABI, RLSContractAddress);
		let supply = await nftContract.methods.totalSupply().call()

		history["web3Object"] = {
			web3: web3,
			connected: true,
			currentAccount: address,
			appTheme: appTheme,
			nftAddress: RLSContractAddress,
			nftContract: nftContract,
			totalSupply: supply,
			pendingList: pendingList,
			closePending: (e) => {closePending(e)},
			closeTx: (e) => {closeTx(e)},
			setPendingList: (e) => {setPendingList(pendingList => [...pendingList, e])},
			messageList: messageList,
    }

		listenForMint()

		await setState({
			web3: web3,
			provider: provider,
			connected: true,
			chainId: chainId,
			networkId: networkId,
			showModal: false,
			pendingRequest: false,
			result: null,
			fetching: false,
			address: address
		});
		// await getAccountAssets();

	}

  const subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
		// Subscribe to provider connection
		provider.on("connect", (info: { chainId: number }) => {
		});
    provider.on("accountsChanged", async (accounts: string[]) => {
      await setState({ address: accounts[0] });
    });
    provider.on("chainChanged", async (chainId: number) => {
      const { web3 } = this.state;
      const networkId = await web3.eth.net.getId();
      await setState({ chainId, networkId });
    });

    provider.on("networkChanged", async (networkId: number) => {
      const { web3 } = state;
      const chainId = await web3.eth.chainId();
      await setState({ chainId, networkId });
    });
  };

  const updateTheme = (t) => {
    localStorage.setItem("rl_theme", t)
    setAppTheme(getTheme(t))
  }


	const listenForMint = () => {
		if (history["web3Object"]) {
			history["web3Object"]["nftContract"].events.Minted().on('data', (res) => {
				setMessageList(prevMessage => ({...prevMessage, [res.transactionHash]: res}))
			})
		}
	}

	const closeTx = (id) => {
		let x = messageList;
		delete x[id]
		setMessageList({...x})
	}

	const closePending = (id) => {
		let x = pendingList;
		var filtered = x.filter(function(value, index, arr){
				return value !== id;
		});
		setPendingList(filtered)
	}

  return (
    <MuiThemeProvider theme={appTheme} >
      <CssBaseline />
          <Navbar key={`navbar`}  active={activePage} updatePage={(e) => {updatePage(e)}} connectWallet={() => {onConnect()}}  address={state.address} web3State={state} history={history} updateTheme={(t) => updateTheme(t)} theme={localStorage.getItem("rl_theme")} />
          <Switch location={history.location}>
						<Route exact path="/" component={Home}/>
						<Route exact path="/mint" component={Mint}/>
						<Route exact path="/about" component={About}/>
						<Route exact path="/collection" component={Wallet}/>
						<Route exact path="/faqs" component={FAQs}/>
						<Route exact path="/team" component={Team}/>
						<Route exact path="/roadmap" component={Roadmap}/>
            <Redirect to="/"/>
          </Switch>
					{
						history["web3Object"]
							?
								<TransactionWidget key={`tx`} updatePage={(e) => {updatePage(e)}} closeTx={(e) => {closeTx(e)}} closePending={(e) => {closePending(e)}} web3={history["web3Object"].web3} pendingList={pendingList} messageList={messageList} currentAccount={history["web3Object"].currentAccount} contract={state.contract} />
							:<></>
					}
    </MuiThemeProvider>

  );
}

export default withRouter(connect()(App))

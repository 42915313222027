import React, {useEffect, useState, useRef} from 'react'
import Countdown, { zeroPad }  from "react-countdown";

import {useHistory} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TwitterLink from "../../assets/twitterlink.png";
import DiscordLink from "../../assets/discord.png";
import MediumLink from "../../assets/med.png";
import SocialsWithTermsAlt from "../../components/socials"

const useStyles = makeStyles(theme => ({
	cardBackground: {
		backgroundColor: theme.body.basePageColorTrans,
		color: theme.text.primary
	},
	gradientText: {
		backgroundImage: theme.text.rareLiquidGradient,
		background: theme.text.rareLiquidGradientWebkit,
		// fontSize: 55
	},
	pageHeight: {
		marginTop: '75px',
		height: 'calc(100vh - 75px)',
		overflow: "overlay",
	},
	cardTitle: {
		fontSize: 40,
		fontWeight: 900
	},
	cardContent: {
		fontSize: 20,
		fontWeight: 500
	},
	cardContentSmall: {
		fontSize: 15,
	},
	cardContentMed: {
		fontSize: 20,
		fontWeight: 900

	}
}),
{ index: 2 })

export default function About(props) {
	const classes = useStyles()

	const myRef = React.createRef()



	const [localState, setLocalState] = useState({
		history: {}
	})

	useEffect(() => {
		setLocalState({
			history: props.history["web3Object"]
		})

	},[props.history["web3Object"]])


	const closePending = (id) => {
		props.history["web3Object"].closePending(id)
	}

	const updatePendingList = (id) => {
		props.history["web3Object"].setPendingList(id);
	}

	const closeTx = (id) => {
		props.history["web3Object"].closeTx(id);
	}



	return (
		<>
			<div className={`h-screen `} ref={myRef} >
				<div
					initial={{ opacity: 0, yScale: 0, y: 50 }}
					animate={{ opacity: 1, yScale: 1, y: 0 }}
					exit={{ opacity: 0, yScale: 0 }}
					transition={{ duration: 2 }}
					className={`h-auto w-full flex flex-col justify-start items-center relative`}
					style={{
						backgroundImage: `url(https://storage.googleapis.com/rareliquid-web/enter.gif)`,
						backgroundPosition: "bottom",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat"
					}}>
					<AnimatePresence
					>
					<div className={`${classes.pageHeight} w-full`}>
						<div className={` w-full md:max-w-screen-lg md:mx-auto md:mb-24`}>
							<div className={`${classes.cardBackground} md:mt-24 p-6 md:p-12`}>
								<div className={`${classes.cardTitle} pb-12`}>
									The FAQs
								</div>
								<div className={`${classes.cardContent}`}>
									<Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel1a-content"
					           id="panel1a-header"
					         >
										 <span className={`${classes.cardContentMed}`}>
											 What are the benefits of Ownership?
										 </span>
					         </AccordionSummary>
					         <AccordionDetails>
										 <ul className={`p-6 ${classes.cardContentSmall}`}>
											<li>- Fire generative NFTs composed of up to 7 traits from 250 attributes- randomly assigned in real-time upon minting </li>
											<li>- You have full commercial rights to your bottle NFT-use it as a personal profile pic, trade it, get a tattoo, 3D print it. It’s yours. Do as you wish</li>
											<li>- Rare Liquid Society membership status with access to exclusive community features on Discord </li>
											<li>- Surprise asset airdrops exclusively for owners</li>
											<li>- Access to membership upgrade opportunities which include physical bottles of Rare Liquids and insured cold storage capacity</li>
											<li>- Exclusive access to limited edition Rare Liquid Society merch</li>
										</ul>
					         </AccordionDetails>
					       </Accordion>

					       <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel2a-content"
					           id="panel2a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 When do Rare Liquids go on sale?
									 </span>
					         </AccordionSummary>
					         <AccordionDetails>
									 	<div className={`p-6 ${classes.cardContentSmall}`}>
											5:00 PM PST on Thursday September 9th.
										</div>
					         </AccordionDetails>
					       </Accordion>

								 <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel3a-content"
					           id="panel3a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 How much do Rare Liquids cost?
									 </span>
					         </AccordionSummary>
									 <AccordionDetails>
									 <div className={`p-6 ${classes.cardContentSmall}`}>
										 Price: .08 ETH each (20 Max per batch)
									 </div>
									</AccordionDetails>
					       </Accordion>

								 <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel3a-content"
					           id="panel3a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 What are the details and rarity distribution of the collection?
									 </span>
					         </AccordionSummary>
									 <AccordionDetails>
									 <div className={`p-6 ${classes.cardContentSmall}`}>
										 10,000 max NFTs living on the Ethereum Blockchain as ERC 721 tokens. Rare Liquids  are randomly assigned up to 7 traits from over 250 attributes of differing rarity assigned in real time upon minting.
									 </div>
									</AccordionDetails>
					       </Accordion>

								 <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel3a-content"
					           id="panel3a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 Have any Rare Liquids been pre-minted?
									 </span>
					         </AccordionSummary>
									 <AccordionDetails>
									 <div className={`p-6 ${classes.cardContentSmall}`}>
										Yes. A total of 446 (4.46% of total) Rare Liquids have been pre-preminted for early project contributors and community reserves. All pre-mints are subject to the same random trait assignment process as those offered in the public sale. The odds of minting a rare Rare Liquid are the same for everyone.									 </div>
									</AccordionDetails>
					       </Accordion>

								 <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel3a-content"
					           id="panel3a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 If I sell my Rare Liquid on the secondary market, is there a royalty fee?
									 </span>
					         </AccordionSummary>
									 <AccordionDetails>
									 <div className={`p-6 ${classes.cardContentSmall}`}>
										 Don’t sell, HODL your bottle. But if you do, OpenSea and the Rare Liquid Society will each receive a 2.5% royalty.
									 </div>
									</AccordionDetails>
					       </Accordion>

								 <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel3a-content"
					           id="panel3a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 Why are we doing this?
									 </span>
					         </AccordionSummary>
									 <AccordionDetails>
									 <div className={`p-6 ${classes.cardContentSmall}`}>
										 Project founders are a mix of award winning artisans, OG defi developers, graphic artists, entrepreneurs, and a master sommelier. We came together over a shared passion for NFTs, rare wine, and fine whiskey. We envision an exciting and supportive corner of the metaverse united by a love for the finest wines, champagnes, whiskeys, foods, and NFTs on the planet.
									 </div>
									</AccordionDetails>
					       </Accordion>

								 <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel3a-content"
					           id="panel3a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 Mission
									 </span>
					         </AccordionSummary>
									 <AccordionDetails>
									 <div className={`p-6 ${classes.cardContentSmall}`}>
										 We are democratizing rare wine and spirits as an alternative asset class. The Rare Liquid Society is one of the first NFT projects that will seamlessly integrate the digital and physical realms.  We are building the future of rare wine and spirit collecting, investing, and enjoyment.
									 </div>
									</AccordionDetails>
					       </Accordion>

								 <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel3a-content"
					           id="panel3a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 Philanthropy
									 </span>
					         </AccordionSummary>
									 <AccordionDetails>
									 <div className={`p-6 ${classes.cardContentSmall}`}>
										Rare Liquid Society will donate 5% of proceeds from the initial sales to charity. One or more nonprofits engaged in promoting mental health services, financial literacy or addressing climate change will be selected.
										</div>
									</AccordionDetails>
					       </Accordion>

								 <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel3a-content"
					           id="panel3a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 What is the RL Index?
									 </span>
					         </AccordionSummary>
									 <AccordionDetails>
									 <div className={`p-6 ${classes.cardContentSmall}`}>
											We are building the largest decentralized and community owned rare wine and spirit collection in the world….The RL Index.  It consists of assets sourced by the RL team, by community vote, and by member submissions. Think NFTX backed by physical bottles of verifiably scarce wines and spirits. Members will have the opportunity to invest as well as redeem and drink bottles from the RL Index. More details to follow.
										</div>
									</AccordionDetails>
					       </Accordion>

								 <Accordion>
					         <AccordionSummary
					           expandIcon={<ExpandMoreIcon />}
					           aria-controls="panel3a-content"
					           id="panel3a-header"
					         >
									 <span className={`${classes.cardContentMed}`}>
										 What is the “My Collection Page”?
									 </span>
					         </AccordionSummary>
									 <AccordionDetails>
									 <div className={`p-6 ${classes.cardContentSmall}`}>
When you mint your Rare Liquids your bottles appear on your “My Collection” page. As you acquire more Rare Liquids your shelfs become stocked. When you click a bottle the metadata of that bottle displays with a link to the Opensea listing.										</div>
									</AccordionDetails>
					       </Accordion>
								</div>
								<SocialsWithTermsAlt />

							</div>
						</div>

					</div>

					</AnimatePresence>

				</div>
			</div>
		</>
	)
}

import React, {useEffect, useState, useRef} from 'react'
import Countdown, { zeroPad }  from "react-countdown";

import {useHistory} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'
import logo from '../../assets/bottle_main.png'
import Wave from 'react-wavify'
import Minter from "../../components/minter.js"
import { history } from '../../store/configureStore'
import Socials from "../../components/socials"

const useStyles = makeStyles(theme => ({
	cardBackground: {
		backgroundColor: theme.body.basePageColorTrans,
		color: theme.text.primary
	},
	gradientText: {
		backgroundImage: theme.text.rareLiquidGradient,
		background: theme.text.rareLiquidGradientWebkit,
		// fontSize: 55
	},
	pageHeight: {
		marginTop: '75px',
		height: 'calc(100vh - 75px)',
		overflow: "auto"
	},
	cardTitle: {
		fontSize: 40,
		fontWeight: 900
	},
	cardContent: {
		fontSize: 20,
		fontWeight: 900
	},
	pageWrapper: {

	}
}),
{ index: 2 })

export default function About(props) {
	const classes = useStyles()

	const myRef = React.createRef()



	const [localState, setLocalState] = useState({
		history: {}
	})

	useEffect(() => {
		setLocalState({
			history: props.history["web3Object"]
		})

	},[props.history["web3Object"]])

	const closePending = (id) => {
		props.history["web3Object"].closePending(id)
	}

	const updatePendingList = (id) => {
		props.history["web3Object"].setPendingList(id);
	}

	const closeTx = (id) => {
		props.history["web3Object"].closeTx(id);
	}

	return (
		<>
			<div className={`h-screen `} ref={myRef} >
				<div
					initial={{ opacity: 0, yScale: 0, y: 50 }}
					animate={{ opacity: 1, yScale: 1, y: 0 }}
					exit={{ opacity: 0, yScale: 0 }}
					transition={{ duration: 2 }}
					className={`h-auto w-full flex flex-col justify-start items-center relative`}
					style={{
						backgroundImage: `url(https://storage.googleapis.com/rareliquid-web/enter.gif)`,
						backgroundPosition: "bottom",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat"
					}}>
					<AnimatePresence
					>
					<div className={`${classes.pageHeight} w-full`}>
						<div className={` w-full h-full md:max-w-screen-lg md:mx-auto md:mb-24`}>
							<div className={`${classes.cardBackground} md:mt-24 p-6 md:p-12`}>
								<div className={`${classes.cardTitle}`}>
									The Rare Liquid Society
								</div>

								<div className={`${classes.cardContent} pt-12`}>
								The Rare Liquid Society is a rebel epicurean army of 10,000 avatars occupying a hidden cave in the metaverse to stage a resistance against a corrupt regime.
									<br/><br/>
									Greedy distributors, brokers, and auction houses have a stranglehold on the rare wine and spirit trade. They push mass market, genetically modified, and counterfeit vintages while artisan winemakers and distillers struggle to survive.

									The Rare Liquid Society is a festive community of defiant degens on a mission to save the world from collecting and drinking shitty adult beverages.
									<br/><br/>
									We are on a quest to save and honor artisan winemakers and distillers. Those who have dedicated their lives to the relentless pursuit of quality over plonk.

									<br/><br/>
									Our premier Rare Liquid NFT release is a purely digital collection of generative avatars inspired by fine wine, spirit, and crypto subcultures.
									<br/><br/><br/>
									Rare Liquid NFT owners are the founding members and catalysts of the Rare Liquid Society.
								</div>
								<Socials />
							</div>
						</div>
					</div>
					</AnimatePresence>

				</div>
			</div>
		</>
	)
}

import React, {useEffect, useState} from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import { makeStyles } from '@material-ui/core/styles'
import getTheme from '../../themes'

const useStyles = makeStyles(theme => ({

	connectorButtons: {
		fontWeight: 900,
		color: theme.text.primary,
		borderBottom: "3px solid transparent",
		borderTop: "3px solid transparent",
		"&:hover": {
			borderBottom: `3px solid ${theme.body.shelfBackgroundColorPink}`,
		}
	},
	connected: {
		fontWeight: `900 !important`,
		color: theme.text.primary,
		borderBottom: `3px solid ${theme.body.shelfBackgroundColorBlue}`,
		borderTop: "3px solid transparent"
	}
}),
{ index: 1 })

export default function Web3Connecter(props) {
	const classes = useStyles()
	const [state, setState] = useState({
    address: null,
		connected: false,
		eth: 0
  });

	useEffect(() => {
		if(props.web3State.connected) {
			props.web3State.web3.eth.getBalance(props.web3State.address).then((bal) => {
				setState({
					address: props.web3State.address,
					connected: props.web3State.connected,
					eth: parseFloat(props.web3State.web3.utils.fromWei(bal)).toFixed(4)
				})
			})
		} else {
			setState({
				connected: false
			})
		}
	},[props.web3State, props.address])

	return (
		<>
      <div className={`${classes.gradientText} ${state.connected ? classes.connected : ''}  flex text-sm h-full`}>
				{
					state.connected ?
						<>
							<div className={`w-auto h-full px-4 flex`}>
								<div className={`px-4 flex items-center justify-center `}>
									<span className={`pr-4`}>{state.address.slice(0,4) + "...." + state.address.substr(-4)}</span>
									<Jazzicon key={`jazzicon`} diameter={15} seed={jsNumberForAddress(state.address)} />
								</div>
								<div className={`px-2 flex items-center justify-center`}>{state.eth} ETH</div>
							</div>
						</>

					:
						<>
							<button id={`_connector`} className={`px-4 h-full ${classes.connectorButtons} w-auto md:w-48 md:text-base`} onClick={() => {props.connectWallet()}}>
								CONNECT
							</button>
						</>
				}
      </div>
    </>
	)
}
